/** @format */

.captcha-dependent.disabled {
	pointer-events: unset;
	cursor: not-allowed;

	> a {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.success, .mce-content-raf-tag > .success {
	border: 1px solid #1ab394;
	padding: 0 3px;
}

.error, .mce-content-raf-tag > .error {
	border: 1px solid #ed5565;
	text-indent: 0 !important;
	min-width: 0 !important;
}

.empty, .mce-content-raf-tag > .empty {
	border: 1px solid #aaa;
	padding: 0 2px;
	min-width: 3em;
	display: inline-block;
	height: 1.09em;
	vertical-align: text-bottom;
}
